<template>
  <div class="coursewareUser"> 
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">数据统计</a>
                <i>></i>
                <a href="javascript:;" @click="init" class="cur-a">发布课件使用</a>
                </span>
            </div>
           <div class="framePage-body">
                <div class="operationControl ">
                    <div class="searchbox">
                        <div title="课件名称" class="searchboxItem ci-full">
                            <span class="itemLabel">课件名称:</span>
                            <el-input v-model="kpointName" type="text" size="small" placeholder="请输入课件名称" clearable />
                        </div>
                        <div title="使用区域" class="searchboxItem ci-full">
                            <span class="itemLabel">使用区域:</span>
                            <el-cascader
                            v-model="areaId"
                            :options="areatreeList"
                            :props="propsarea"
                            :disabled="disabled"
                            clearable
                            filterable
                            size="small"
                            ></el-cascader>
                        </div>
                        <div title="使用时间:" class="searchboxItem ci-full">
                            <span class="itemLabel">使用时间:</span>
                            <el-date-picker
                            clearable
                            size="small"
                            v-model="userTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                           value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </div>
                        <div class="df">
                            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
                        </div>
                    </div>
                </div>
                
                 <div class="framePage-scroll">
                    <div class="ovy-a">
                        <div style="margin-bottom: 15px">
                          <span>学习完成人数： {{totalCompleteCount}} 人</span>
                        </div>
                      <el-table
                        ref="multipleTable"
                        :data="tableData"
                        :height="tableHeight"
                        size="small"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="tableHeader"
                        stripe
                      >
                        <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
                          <el-table-column label="课件名称" align="left" show-overflow-tooltip prop="kpointName" />
                        <el-table-column label="课件学时" align="right" show-overflow-tooltip prop="lessonNum" />
                        <el-table-column label="使用区域" align="left" show-overflow-tooltip prop="areaNamePath" min-width="180"/>
                          <el-table-column label="使用开始时间" align="left" show-overflow-tooltip prop="startDate">
                              <template slot-scope="scope">
                                <span>{{scope.row.startDate|momentDate}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="使用结束时间" align="left" show-overflow-tooltip prop="endDate">
                              <template slot-scope="scope">
                                <span>{{scope.row.endDate|momentDate}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="学习完成人数" align="right" show-overflow-tooltip prop="completeCount" />
                        <Empty slot="empty" />
                      </el-table>
                    </div>
                  </div>
                  <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
         
        
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/coursewareUser",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      kpointName:"",
      areaId: "",
      userTime:"",
      totalCompleteCount:"",
      totalLessonNum:"",
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
    
    };
  },
  computed: {},
  created() {
    this.init();
  
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getareatree();
      this.getData();
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
   
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
     
      if(this.userTime&&this.userTime.length>0){
        params.startDate=this.userTime[0];
         params.endDate=this.userTime[1];
      }else{
         this.userTime=[];
      }
      this.doFetch({
        url: "/biz/buy/stat/kpointUse",
        params,
        pageNum,
      });
      this.$post("/biz/buy/stat/kpointUseStat",{...params}).then(ret => {
        this.totalCompleteCount=ret.data.totalCompleteCount;
        this.totalLessonNum=ret.data.totalLessonNum;
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 100;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  mounted: function() {}
};
</script>
<style lang="less" scope>
.coursewareUser{
  height: 100%;
}
</style>